export const version = '3.0.0 pt 5.2: June 27, 2024: Fall of the Octeracts 2'

/**
 * PSEUDO DO NOT CHANGE THIS LINE
 * PSEUDO DO NOT CHANGE THIS LINE
 * PSEUDO DO NOT CHANGE THIS LINE
 * PSEUDO DO NOT CHANGE THIS LINE
 * PSEUDO DO NOT CHANGE THIS LINE
 * PSEUDO DO NOT CHANGE THIS LINE
 */
export const testing: boolean = false
export const lastUpdated = new Date(Date.UTC(2024, 9, 12, 1, 51, 6))
/**
 * CHANGE THIS ONE INSTEAD
 */
export const prod: boolean = true
